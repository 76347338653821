import CmsLayout from "app/layouts/CmsLayout"
import { Head } from "blitz"
import { tw } from "twind"

// ------------------------------------------------------
// This page is rendered if a route match is not found
// ------------------------------------------------------
// ts-prune-ignore-next
export default function Page404() {
  const statusCode = 404

  const title = "This page could not be found"

  return (
    <>
      <Head>
        <title>
          {statusCode}: {title}
        </title>
      </Head>
      <CmsLayout>
        <h1 className={tw(`text(xl center)`)}>{title}</h1>
      </CmsLayout>
    </>
  )
}
